import { styled } from '@mui/material';
import { mergePersonalization } from '../../utils/style';

export const BoxWrapper = styled('div')(({ theme, cols }) => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  minHeight: theme.spacings.unit(125),
  [theme.breakpointsMediaQuery.up('xs')]: {
    width: cols ? `calc(100% / ${cols?.numberOfColumnsXS || 1})` : '100%',
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    width: cols ? `calc(100% / ${cols?.numberOfColumnsSM || 1})` : '100%',
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    width: cols
      ? `calc(100% / ${cols?.numberOfColumnsM || 2} - ${theme.spacings.unit(
        9
      )})`
      : '50%',
  },
  [theme.breakpointsMediaQuery.up('lg')]: {
    width: cols
      ? `calc(100% / ${cols?.numberOfColumnsLG || 2} - ${theme.spacings.unit(
        9
      )})`
      : '50%',
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    width: cols
      ? `calc(100% / ${cols?.numberOfColumnsXL || 2} - ${theme.spacings.unit(
        9
      )})`
      : '50%',
  },
}));

export const ImageWrapper = styled('div')(({ theme, url }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  padding: theme.spacings.unit(20),
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  background: url
    ? `linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 100%), url(${url}), lightgray 50% / cover no-repeat`
    : '',
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  zIndex: 1,
  position: 'relative',
  gap: theme.spacings.unit(4),
}));

export const TitleWrapper = styled('div')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      color: theme.colors.white,
      fontSize: theme.fonts.utility.size(16),
      fontWeight: 500,
      lineHeight: '120%',
      letterSpacing: theme.spacings.unit(-0.12),
    },
    personalization
  )
);

export const DescriptionWrapper = styled('div')(({ theme, personalization }) =>
  mergePersonalization(
    theme,
    {
      color: theme.colors.secondaryTextWhite,
      fontSize: theme.fonts.utility.size(9),
      fontWeight: 400,
      lineHeight: '160%',
    },
    personalization
  )
);

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpointsMediaQuery.down('md')]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& > a': {
    borderRadius: theme.spacings.unit(0.25),
    padding: `${theme.spacings.unit(3.75)} ${theme.spacings.unit(5)}`,
    fontSize: theme.fonts.utility.size(7),
    fontWeight: 700,
    lineHeight: '125%',
    letterSpacing: theme.spacings.unit(0.49),
    textTransform: 'uppercase',
  },
}));
