import { styled } from '@mui/material';

export const OuterWrapper = styled('div')(({ theme, color }) => ({
  backgroundColor: color || `${theme.colors.secondaryBackground}`,
}));

export const InnerWrapper = styled('div')(({ theme }) => ({
  maxWidth: theme.spacings.unit(490),
  width: '100%',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'row',
  gap: `${theme.spacings.unit(9)}`,
  minHeight: theme.spacings.unit(140),
  padding: theme.spacings.unit(24),
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  [theme.breakpointsMediaQuery.down('md')]: {
    padding: `${theme.spacings.unit(14)} 0`,
    flexDirection: 'column',
    minHeight: theme.spacings.unit(250),
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    paddingLeft: theme.spacings.unit(20 / 4),
    paddingRight: theme.spacings.unit(20 / 4),
  },
}));
